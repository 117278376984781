import React, { useState, useEffect } from "react";
import "./login.css"
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import Api from "../../services/Api";

const Login = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [isLoggedIn, setisLoggedIn] = useState(false);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Preencha E-mai e Password!")
      return
    }
    try {
      const { data } = await Api.post("/api/v1/login", {
        email: email,
        password: password
      })

      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('token', JSON.stringify(data?.token));
      setisLoggedIn(true)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message)
      } else {
        setError(t('access_error'))
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      localStorage.clear()
    } else {
      navigate("/connect-devices");
    }
  }, [navigate, isLoggedIn])

  return (
    <>
      <Header />
      <Container fluid className="d-flex p-0" style={{height: "93vh"}}>
        <Row className="w-100 m-0">
          <Col className="d-flex align-items-center justify-content-center p-4" style={{ background: "#f4f4f4" }}>
            <div className="div-login">
              <h2 className="text-center my-4">{t('login')}</h2>

              <FloatingLabel controlId="floatingInput" label={t('email')} className="mb-3">
                <Form.Control
                  type="email"
                  value={email}
                  placeholder={t('email')}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FloatingLabel>

              <FloatingLabel controlId="floatingPassword" label={t('password')}>
                <Form.Control
                  type="password"
                  value={password}
                  placeholder={t('password')}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FloatingLabel>

              <Button
                variant="success"
                className="mt-3 w-100"
                size="lg"
                onClick={() => {
                  handleLogin()
                }}
              >
                {t('enter')}
              </Button>

              {error && <p className="msg-error">{error}</p>}
              <div className="mt-3 text-center">
                <p>
                  {t('dont_have_an_account')} <Link to="/register">{t('register_here')}</Link>
                </p>
              </div>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
