import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"


const Header = (props) => {
  const { page } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", t('description'));
    }
  }, [i18n.language, t]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLoginRedirect = () => {
    navigate('/login')
  }

  return (
    <Navbar
      bg="dark"
      expand={'sm'}
      data-bs-theme="dark"
      className="bg-body-tertiary p-0"
      style={{ height: "7vh" }}
    >
      <Container fluid>
        <Navbar.Brand href="/" className='title-connect-devices'>{process.env.REACT_APP_NAME}</Navbar.Brand>
        <Nav className="ml-auto d-flex align-items-center">
          <NavDropdown
            className='m-1'
            title={t('language')}
            id="language-nav-dropdown"
          >
            <NavDropdown.Item onClick={() => changeLanguage('en')}>
              {t('en')}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('es')}>
              {t('es')}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('pt')}>
              {t('pt')}
            </NavDropdown.Item>
          </NavDropdown>

          {page === 'home' ?
            <Button
              variant="outline-warning"
              className="ms-3"
              onClick={handleLoginRedirect}
            >
              {t('login')}
            </Button>
            : null}
        </Nav>
      </Container>
    </Navbar>
  )
}
export default Header;
