import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Fragment } from 'react';

import Home from './pages/home/home';
import Login from './pages/login/login';
import Conected from './pages/conected/conected';
import Register from './pages/register/register';
import useAuth from './hooks/useAuth';
import { WebSocketProvider } from './hooks/webSocketContext';

import i18n from './utils/i18n';

const App = () => {

  const Private = ({ Item }) => {
    const signed = useAuth();
    return signed ? <Item /> : <Login />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <Routes>

            <Route
              path="/connect-devices"
              element={
                <Private Item={() => (
                  <WebSocketProvider>
                    <Conected />
                  </WebSocketProvider>
                )}
                />}
            />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Home />} />

          </Routes>
        </Fragment>
      </BrowserRouter>
    </div>
  );
}

export default App;
