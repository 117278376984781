import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useWebSocket } from '../../hooks/webSocketContext';
import Header from './header/header';
import ImgDevices from '../../components/imgDevices/imgDevices';
import Img from '../../components/img/img';
import { throttle } from 'lodash';
import { getToken } from '../../hooks/sessionActions';
import Api from '../../services/Api';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../components/confirmDialog/confirmDialog';

let lastSendTime = 0;
let animationFrameId;

let roomIdGlobal;
let passwordGlobal;

const throttleTime = 33;
const SAVE_INTERVAL = 60000;

const Conected = (props) => {
  const socket = useWebSocket();
  const { t, i18n } = useTranslation();

  const [connectionStabished, setConnectionStabished] = useState(false);
  const [connectionDevice, setConnectionDevice] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const [widthRatio, setWidthRatio] = useState(1);
  const [heightRatio, setHeigthRatio] = useState(1);

  const [monitoCount, setMonitoCount] = useState(1);
  const [selectedMonitor, setSelectedMonitor] = useState(1);

  const [statusDevices, setStatusDevices] = useState([]);

  const [user, setUser] = useState(() => {
    const savedData = localStorage.getItem('user');
    return savedData ? JSON.parse(savedData) : { name: '', email: '', devices: [] };
  });


  const [roomId, setRoomId] = useState(() => localStorage.getItem('roomId') || '');
  const [password, setPassword] = useState(() => localStorage.getItem('password') || '');

  useEffect(() => {
    localStorage.setItem('roomId', roomId);
    roomIdGlobal = roomId;
  }, [roomId]);

  useEffect(() => {
    localStorage.setItem('password', password);
    passwordGlobal = password;
  }, [password]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleEditDevice = async (id, newName) => {
    try {
      await Api.put("/api/v1/update-device-name", {
        token: getToken(),
        user_id: user._id,
        device_id: id,
        new_name: newName
      });
      handleUser();
    } catch (error) {
      console.log(error);
    }
  };

  const [showDialog, setShowDialog] = useState(false);
  const [confirmed, setConfirmed] = useState(null);

  const openConfirmDialog = (message) => {
    return new Promise((resolve) => {
      const handleConfirm = (result) => {
        setShowDialog(false);
        resolve(result);
      };
      setConfirmed(() => <ConfirmDialog message={message} onConfirm={handleConfirm} />);
      setShowDialog(true);
    });
  };

  const handleProcess = async () => {
    const result = await openConfirmDialog("Você tem certeza que deseja continuar?");
    if (result) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteDevice = async (id) => {
    let confirm = await handleProcess();
    if (!confirm) return;

    try {
      await Api.delete(`/api/v1/delete-device-user?user_id=${user._id}&device_id=${id}`, {
        headers: {
          token: getToken()
        }
      });

      handleUser();
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusDevices = async (devicesId) => {
    try {
      const { data } = await Api.post("/api/v1/connected-devices-filter", { token: getToken(), filter: devicesId });
      setStatusDevices(data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleUser = async () => {
    try {
      const { data } = await Api.get(`/api/v1/get-users-id?user_id=${user._id}`, {
        headers: { token: getToken() }
      });
      localStorage.setItem('user', JSON.stringify(data));
      setUser(data);
    } catch (error) {
      console.warn('Error fetching user:', error);
    }
  };

  useEffect(() => {
    if (!user?.devices?.length) {
      return;
    }

    const updateDevicesStatus = () => {
      const devicesId = getRoomList(user?.devices);
      if (devicesId.length) {
        handleStatusDevices(devicesId);
      }
    };

    updateDevicesStatus();
    handleUser();

    const intervalId = setInterval(() => {
      updateDevicesStatus();
    }, 20000);
    return () => clearInterval(intervalId);
  }, []);

  const getRoomList = (data) => {
    return data.map(item => item.id);
  };

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const { x, y } = calculateCoordinates(e.nativeEvent, rect);

    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    animationFrameId = requestAnimationFrame(() => {
      const currentTime = Date.now();

      if (currentTime - lastSendTime > throttleTime) {
        sendMessage({
          type: 'message',
          room: roomIdGlobal,
          password: passwordGlobal,
          action: 'move',
          x,
          y
        });
        lastSendTime = currentTime;
      }
    });
  };

  const handleImageClick = (event) => {
    event.preventDefault();

    const rect = event.target.getBoundingClientRect();
    const { x, y } = calculateCoordinates(event, rect);

    sendMessage({
      type: 'message',
      room: roomIdGlobal,
      password: passwordGlobal,
      action: 'click',
      button: event.button === 2 ? 'right' : 'left',
      x,
      y
    });
  };

  const calculateCoordinates = (event, rect) => {
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    const x = offsetX * widthRatio;
    const y = offsetY * heightRatio;

    return { x, y };
  };

  const handleSendConnect = () => {
    if (!roomIdGlobal || !passwordGlobal) {
      alert("Insira ID e Senha do Parceiro!")
      return;
    }

    socket.requestedConnection = true;
    sendMessage({
      type: 'join',
      room: roomIdGlobal,
      password: passwordGlobal,
      email: user?.email || "",
      userId: user?._id || "",
      token: getToken(),
      action: 'connect'
    });
  };

  const handleConnectDevice = (password, roomId) => {
    if (!roomId || !password) {
      alert("Insira ID e Senha do Parceiro!")
      return;
    }

    socket.requestedConnection = true;
    setRoomId(roomId);
    setPassword(password);

    sendMessage({
      type: 'join',
      room: roomId,
      password: password,
      email: user?.email || "",
      userId: user?._id || "",
      token: getToken(),
      action: 'connect'
    });
  }

  const handleImage = () => {
    sendMessage({
      type: 'message',
      room: roomIdGlobal,
      password: passwordGlobal,
      email: user?.email || "",
      action: 'connect'
    });
  }

  const handleChangeMonitor = (monitor) => {
    sendMessage({
      type: 'message',
      room: roomIdGlobal,
      password: passwordGlobal,
      email: user?.email || "",
      action: 'change_monitor',
      number: monitor
    });
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!isHovered) return;

      const keyCombination = [];

      if (event.ctrlKey) keyCombination.push('Ctrl');
      if (event.altKey) keyCombination.push('Alt');
      if (event.shiftKey) keyCombination.push('Shift');
      if (event.metaKey) keyCombination.push('Meta');

      let key = event.key;

      if (key.length !== 1) {
        key = event.code;
      }

      keyCombination.push(key);
      const combination = keyCombination.join('+');

      if (event.ctrlKey && ['s', 'a', 'p', 'f', 'o'].includes(event.key.toLowerCase())) {
        event.preventDefault(); // Impede o comportamento padrão de Ctrl+S, Ctrl+A, Ctrl+P, etc.
      }

      sendMessage({
        type: 'message',
        room: roomIdGlobal,
        password: passwordGlobal,
        action: 'keypress',
        key: combination
      });
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [connectionStabished, isHovered]);


  const handleScrollWheel = useCallback((event) => {
    if (!isHovered) return;

    const { deltaY } = event;

    sendMessage({
      type: 'message',
      room: roomIdGlobal,
      password: passwordGlobal,
      action: 'scroll',
      value: deltaY > 0 ? -1 : 1,
    });
  }, [roomIdGlobal, passwordGlobal]);

  const throttledHandleScroll = useMemo(
    () => throttle(handleScrollWheel, throttleTime),
    [handleScrollWheel, throttleTime]
  );

  useEffect(() => {
    window.addEventListener('wheel', throttledHandleScroll);
    return () => {
      window.removeEventListener('wheel', throttledHandleScroll);
    };
  }, [throttledHandleScroll]);

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
      console.log("sendMessage", JSON.stringify(message));
    } else {
      console.log("Erro ao enviar dados via socket!!!",);
    }
  };


  const handleImageUpdate = (json_msg, img, width_ratio, height_ratio, monitor_count, selected_monitor) => {
    if (!socket.requestedConnection) return;

    const newImageSrc = `data:image/png;base64,${img}`;
    const currentTime = new Date().getTime();

    if (imageSrc !== newImageSrc) {
      setImageSrc(newImageSrc);

      if (currentTime - socket.lastSavedTime > SAVE_INTERVAL) {
        socket.lastSavedTime = currentTime;

        sendMessage({
          type: 'save',
          room: roomIdGlobal,
          password: passwordGlobal,
          email: user?.email || "",
          userId: user?._id || "",
          token: getToken(),
          img_b64: newImageSrc
        });
      }
    }

    setWidthRatio((prev) => (prev !== width_ratio ? width_ratio : prev));
    setHeigthRatio((prev) => (prev !== height_ratio ? height_ratio : prev));
    setMonitoCount((prev) => (prev !== monitor_count ? monitor_count : prev));
    setSelectedMonitor((prev) => (prev !== selected_monitor ? selected_monitor : prev));

    if (!connectionDevice) {
      setConnectionDevice(true);
    }
    return;
  }

  useEffect(() => {
    if (!socket) return;

    const connectClient = () => {
      if (!user) {
        console.warn('User não carregado.');
        return;
      }

      if (socket.readyState !== WebSocket.OPEN) {
        console.warn('WebSocket não está conectado.');
        return;
      }

      socket.lastSavedTime = "";
      socket.requestedConnection = true;

      socket.onmessage = (event) => {
        try {
          const json_msg = JSON.parse(event.data);
          handleWebSocketMessage(json_msg);
        } catch (error) {
          console.error('Erro ao processar a mensagem do socket:', error);
        }
      };
    };

    const timer = setTimeout(connectClient, 1000);
    return () => clearTimeout(timer);
  }, [socket]);


  const handleWebSocketMessage = useCallback((json_msg) => {
    const { img, width_ratio, height_ratio, monitor_count, selected_monitor } = json_msg?.content || {};

    if (img) {
      handleImageUpdate(json_msg, img, width_ratio, height_ratio, monitor_count, selected_monitor);
      return
    }

    switch (json_msg.type) {
      case "disconnect":
        console.log("Servidor solicitou desconexão");
        handleDeviceDisconnect();
        break;

      case "connected":
        console.log("Conectado ao servidor");
        setConnectionStabished(true);
        break;

      case "join-success":
        console.log("Conexão de join bem-sucedida");
        handleDeviceConnect();
        handleImage();
        break;

      case "join-error":
        console.error("Erro ao conectar!");
        handleDeviceDisconnect();
        if (json_msg?.message) {
          alert(json_msg?.message)
        }
        break;

      case "device-disconnected":
        alert('Equipamento Desconectado!')
        handleDeviceDisconnect()
        break

      case "disconnect-notification":
        alert('Equipamento Desconectado!')
        setConnectionDevice(false);
        handleDeviceDisconnect()
        break

      case "pong":
        break

      case "client-status":
        break

      default:
        console.log("Mensagem sem ação:", json_msg);
    }

  }, [imageSrc, roomIdGlobal, passwordGlobal, connectionDevice]);


  const handleDisconnect = async () => {
    socket.requestedConnection = false;

    sendMessage({
      type: 'message',
      room: roomIdGlobal,
      password: passwordGlobal,
      email: user?.email || "",
      action: 'disconnect'
    });

    sendMessage({
      type: 'leave',
      room: roomIdGlobal,
      password: passwordGlobal,
      email: user?.email || "",
      action: 'disconnect'
    });

    setPassword('')
    setRoomId('')
    handleDeviceDisconnect();
  };

  const handleDeviceConnect = async () => {
    setConnectionDevice(true);
    setConnectionStabished(true);
  }

  const handleDeviceDisconnect = async () => {
    setImageSrc('');
    setMonitoCount(1);
    setConnectionDevice(false);
    setConnectionStabished(false);
  }

  return (
    <>
      <Header
        handleSendConnect={handleSendConnect}
        password={password}
        roomId={roomId}
        setRoomId={setRoomId}
        setPassword={setPassword}
        connectionDevice={connectionDevice}
        handleChangeMonitor={handleChangeMonitor}
        monitoCount={monitoCount}
        selectedMonitor={selectedMonitor}
        handleDisconnect={handleDisconnect}
      />

      {showDialog && confirmed}

      <Container fluid>
        {imageSrc ? (
          <Img
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleMouseMove={handleMouseMove}
            handleImageClick={handleImageClick}
            imageSrc={imageSrc}
          />
        ) : user?.devices?.length > 0 ? (
          <Row>
            {user.devices.map((device, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                <ImgDevices
                  device={device}
                  statusDevices={statusDevices}
                  handleConnectDevice={handleConnectDevice}
                  handleEditDevice={handleEditDevice}
                  handleDeleteDevice={handleDeleteDevice}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <h3 className='mt-3'>{t('no_devices')}</h3>
        )}

      </Container>
    </>
  );
};

export default Conected;
