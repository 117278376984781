import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header/header';
import './home.css';

const Home = () => {
  const { t } = useTranslation();

  const handleDownload = (platform) => {
    window.open(`${process.env.REACT_APP_API_PCLINKER}/api/v1/download-app/${platform}`);
  };

  return (
    <>
      <Header page={"home"} />
      <div className="text-center bg-dark text-light py-5" style={{ height: '93vh' }}>
        <Container>
          <Row>
            <Col>
              <h1 className="display-3">{process.env.REACT_APP_NAME}</h1>
              <p className="lead">{t('best_choice')}</p>

              <div className="d-flex justify-content-center">
                <Button
                  className="me-3"
                  variant="outline-success"
                  onClick={() => handleDownload('windows')}
                  size="lg"
                >
                  {t('download_windows')}
                </Button>

                <Button
                  className="me-3"
                  variant="outline-success"
                  onClick={() => handleDownload('linux')}
                  size="lg"
                >
                  {t('download_linux')}
                </Button>

                <Button
                  variant="outline-success"
                  onClick={() => handleDownload('mac')}
                  size="lg"
                >
                  {t('download_mac')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
