import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaUser } from "react-icons/fa";
import useAuth from '../../hooks/useAuth';
import useClearLocalStorage from '../../hooks/useClearLocalStorage';

const UserConfigs = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const clearStorage = useClearLocalStorage();

  const islogged = useAuth();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", t('description'));
    }
  }, [i18n.language, t]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleExit = () => {
    clearStorage(['user', 'token', 'roomId', 'password']);
    navigate('/');
  };

  return (
    <NavDropdown
      className='m-1 dropstart'
      title={<FaUser size={18} color='#f4f4f4' />}
      id="basic-nav-dropdown"
    >

      <NavDropdown title={t('language')} drop="end" id="submenu-dropdown">
        <NavDropdown.Item onClick={() => changeLanguage('en')}>
          {t('en')}
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => changeLanguage('es')}>
          {t('es')}
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => changeLanguage('pt')}>
          {t('pt')}
        </NavDropdown.Item>
      </NavDropdown>

      {/* <NavDropdown.Divider /> */}

      <NavDropdown.Item onClick={islogged ? handleExit : handleLogin}>
        {islogged ? t('exit') : t('login')}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserConfigs;
