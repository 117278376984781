import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import UserConfigs from '../../../components/userConfigs/userConfigs';
import './header.css';

const Header = ({
  handleSendConnect,
  password,
  setPassword,
  roomId,
  setRoomId,
  connectionDevice,
  handleChangeMonitor,
  monitoCount,
  selectedMonitor,
  handleDisconnect
}) => {

  const { t, i18n } = useTranslation();


  //const [showDropdown, setShowDropdown] = useState(false);

  const maxMonitors = Math.min(monitoCount, 3);



  return (
    <Navbar
      bg="dark"
      expand={'sm'}
      data-bs-theme="dark"
      className="bg-body-tertiary p-0"
      style={{ height: "7vh" }}
    >
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Navbar.Brand className='title-connect-devices'>{process.env.REACT_APP_NAME}</Navbar.Brand>
        <div className='d-flex align-items-center'>

          {Array.from({ length: maxMonitors }, (_, index) => (
            <Button
              key={index}
              size="sm"
              className="m-1"
              disabled={selectedMonitor === index + 1}
              variant={selectedMonitor === index + 1 ? "secondary" : "outline-secondary"}
              onClick={() => handleChangeMonitor(index + 1)}
            >
              {t('screen')}{`${index + 1}`}
            </Button>
          ))}

          <InputGroup size='sm' className="m-1">
            <InputGroup.Text>{t('id')}</InputGroup.Text>
            <Form.Control
              type='text'
              placeholder={t('id')}
              value={roomId}
              maxLength={15}
              onChange={(e) => setRoomId(e.target.value)}
            />
          </InputGroup>

          <InputGroup size='sm' className="m-1">
            <InputGroup.Text>{t('password')}</InputGroup.Text>
            <Form.Control
              type='text'
              placeholder={t('password')}
              value={password}
              maxLength={15}
              onChange={(e) => setPassword(e.target.value.toLowerCase())}
            />
          </InputGroup>

          {connectionDevice ? (
            <Button
              size='sm'
              className="m-1"
              variant='outline-warning'
              onClick={handleDisconnect}
            >
              {t('disconnect')}
            </Button>
          ) : (
            <Button
              size='sm'
              className="m-1"
              variant='success'
              onClick={handleSendConnect}
            >
              {t('connect')}
            </Button>
          )}

          <UserConfigs />

        </div>
      </Container>
    </Navbar>
  );
}

export default Header;