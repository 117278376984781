export const checkAuth = () => {
    const storedUser = localStorage.getItem('user');
  
    if (!storedUser) return false;
  
    const user = JSON.parse(storedUser);
  
    if (!user || !user.date) return false;
  
    const currentDate = new Date();
    const lastLogDate = new Date(user.date);
    const diffTime = Math.abs(currentDate - lastLogDate);
  
    return diffTime <= 43200 * 1000;
  }
  
  export const getToken = () =>
    JSON.parse(localStorage.getItem('user')) != null
      ? JSON.parse(localStorage.getItem('user')).token
      : null;
  